import React, { Component } from 'react'
import data from '../data.json';

const COMMON = 1
const RARE = 2
const EPIC = 3
const LEGENDARY = 4

class IMTCrystalPlanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blue: 552000,
            red: 137000,
            startBlue: 0,
            startRed: 0,
            rarity: EPIC,
            currentLevel: 21,
            targetLevel: 30,
            levelArr: [],
            promotionArr: [],
            totalBlue: 0,
            totalModifiedUpgrade: 0,
            totalRed: 0,
            totalModifiedPromotion: 0,
            rankRequirement: 0
        }

        this.handleInput = this.handleInput.bind(this)
        this.validateLevelInput = this.validateLevelInput.bind(this)
        this.calculateClicked = this.calculateClicked.bind(this)
        this.findMaxIndex = this.findMaxIndex.bind(this)
        this.findMinIndex = this.findMinIndex.bind(this)
        this.increaseBlueCrystal = this.increaseBlueCrystal.bind(this)
        this.decreaseBlueCrystal = this.decreaseBlueCrystal.bind(this)
        this.increaseRedCrystal = this.increaseRedCrystal.bind(this)
        this.decreaseRedCrystal = this.decreaseRedCrystal.bind(this)
        this.refreshBlueTotals = this.refreshBlueTotals.bind(this)
        this.refreshRedTotals = this.refreshRedTotals.bind(this)
        this.displayAmount = this.displayAmount.bind(this)
        this.showBorder = this.showBorder.bind(this)
        this.hideBorder = this.hideBorder.bind(this)
        this.calculateBlue = this.calculateBlue.bind(this)
        this.calculateRed = this.calculateRed.bind(this)
    }

    calculateClicked() {
        var upgrades = []
        var promotions = []
        var levelArr = []
        var promotionArr = []
        var rankRequirement = 0
        switch (this.state.rarity) {
            case COMMON:
                upgrades = data.common.upgrades
                promotions = data.common.promotions
                break;
            case RARE:
                upgrades = data.rare.upgrades
                promotions = data.rare.promotions
                break;
            case EPIC:
                upgrades = data.epic.upgrades
                promotions = data.epic.promotions
                break;
            case LEGENDARY:
                upgrades = data.legendary.upgrades
                promotions = data.legendary.promotions
                break;
            default:
                upgrades = data.epic.upgrades
                promotions = data.epic.promotions
        }

        for (let i = this.state.currentLevel - 1; i < this.state.targetLevel; i++) {
            levelArr.push([i + 1, upgrades[i], 0, upgrades[i]])
        }


        for (let i = Math.ceil(this.state.currentLevel / 10) - 1; i <= Math.floor(this.state.targetLevel / 10) - 1; i++) {
            promotionArr.push([(i + 1) * 10, promotions[i], 0, promotions[i]])
        }

        if (this.state.targetLevel >= 20 && promotionArr.length > 0) {
            rankRequirement = Math.floor(this.state.targetLevel / 10)
        }


        this.setState({ levelArr: levelArr, promotionArr: promotionArr, rankRequirement: rankRequirement }, () => { this.refreshBlueTotals(); this.refreshRedTotals(); })
    }

    handleInput(event) {
        if (parseInt(event.target.value) > 0) {
            this.setState({ [event.target.name]: parseInt(event.target.value) })
        }
        else {
            this.setState({ [event.target.name]: event.target.value })
        }
    }

    validateLevelInput(event) {
        if (parseInt(event.target.value) <= 0) {
            event.target.value = 1
        }
        else if (parseInt(event.target.value) > 50) {
            event.target.value = 50
        }
        this.handleInput(event)
    }

    findMaxIndex(arr) {
        var max = 0
        var index = -1
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][3] > max) {
                max = arr[i][3]
                index = i
            }
        }
        return index
    }

    findMinIndex(arr) {
        var min = Number.POSITIVE_INFINITY
        var index = -1
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][3] < min && arr[i][2] > 0) {
                min = arr[i][3]
                index = i
            }
        }
        return index
    }

    increaseBlueCrystal() {
        let index = this.findMaxIndex(this.state.levelArr)
        if (index == -1) return
        var levelArr = this.state.levelArr
        levelArr[index] = [levelArr[index][0], levelArr[index][1], levelArr[index][2] + 1, levelArr[index][1] / Math.pow(2, levelArr[index][2] + 1)]
        this.setState({ levelArr: levelArr }, () => { this.refreshBlueTotals() })
    }

    decreaseBlueCrystal() {
        let index = this.findMinIndex(this.state.levelArr)
        if (index == -1) return
        var levelArr = this.state.levelArr
        levelArr[index] = [levelArr[index][0], levelArr[index][1], levelArr[index][2] - 1, levelArr[index][1] / Math.pow(2, levelArr[index][2] - 1)]
        this.setState({ levelArr: levelArr }, () => { this.refreshBlueTotals() })
    }

    increaseRedCrystal() {
        let index = this.findMaxIndex(this.state.promotionArr)
        if (index == -1) return
        var promotionArr = this.state.promotionArr
        promotionArr[index] = [promotionArr[index][0], promotionArr[index][1], promotionArr[index][2] + 1, promotionArr[index][1] / Math.pow(2, promotionArr[index][2] + 1)]
        this.setState({ promotionArr: promotionArr }, () => { this.refreshRedTotals() })
    }

    decreaseRedCrystal() {
        let index = this.findMinIndex(this.state.promotionArr)
        if (index == -1) return
        var promotionArr = this.state.promotionArr
        promotionArr[index] = [promotionArr[index][0], promotionArr[index][1], promotionArr[index][2] - 1, promotionArr[index][1] / Math.pow(2, promotionArr[index][2] - 1)]
        this.setState({ promotionArr: promotionArr }, () => { this.refreshRedTotals() })
    }

    refreshBlueTotals() {
        var totalBlue = 0
        var totalModifiedUpgrade = 0
        for (let i = 0; i < this.state.levelArr.length; i++) {
            totalBlue += this.state.levelArr[i][2]
            totalModifiedUpgrade += this.state.levelArr[i][3]
        }

        this.setState({ totalBlue: totalBlue, totalModifiedUpgrade: totalModifiedUpgrade })
    }

    refreshRedTotals() {
        var totalRed = 0
        var totalModifiedPromotion = 0
        for (let i = 0; i < this.state.promotionArr.length; i++) {
            totalRed += this.state.promotionArr[i][2]
            totalModifiedPromotion += this.state.promotionArr[i][3]
        }

        this.setState({ totalRed: totalRed, totalModifiedPromotion: totalModifiedPromotion })
    }

    showBorder(event) {
        event.target.className = "textbox"
    }

    hideBorder(event) {
        event.target.className = "textbox textbox-no-border"
    }

    calculateBlue(event) {
        var targetCrystal = 0
        if (parseInt(event.target.value) > 0) {
            targetCrystal = event.target.value
        }
        if (targetCrystal > this.state.totalBlue) {
            for (var i = this.state.totalBlue; i < targetCrystal; i++) {
                this.increaseBlueCrystal()
            }
        } else if (targetCrystal < this.state.totalBlue) {
            for (var i = this.state.totalBlue; i > targetCrystal; i--) {
                this.decreaseBlueCrystal()
            }
        }
    }

    calculateRed(event) {
        var targetCrystal = 0
        if (parseInt(event.target.value) > 0) {
            targetCrystal = parseInt(event.target.value)
        }
        if (targetCrystal > this.state.totalRed) {
            for (var i = this.state.totalRed; i < targetCrystal; i++) {
                this.increaseRedCrystal()
            }
        } else if (targetCrystal < this.state.totalRed) {
            for (var i = this.state.totalRed; i > targetCrystal; i--) {
                this.decreaseRedCrystal()
            }
        }
    }

    displayAmount(amount) {
        let prefixArr = ["", "K", "M", "B", "T", "aa", "ab", "ac", "ad", "ae", "af", "ag", "ah", "ai", "aj", "ak", "al", "am", "an", "ao", "ap"]
        var ind = 0
        var prefix = ""
        while (amount > 1000) {
            amount /= 1000
            ind += 1
            prefix = prefixArr[ind]
        }
        return amount.toPrecision(3).toString() + " " + prefix
    }

    render() {
        return (
            <div className="container">
                <h1>Anima's IMT Crystal Planner</h1>
                <div>
                    <div className='inline-block div'>Current ML income</div>
                    <div className='inline-block div'>
                        Blue: <input type="text" name='blue' className='blue' value={this.state.blue} onChange={this.handleInput}></input>
                    </div>
                    <div className='inline-block div'>
                        Red: <input type="text" name='red' className="red" value={this.state.red} onChange={this.handleInput}></input>
                    </div>
                </div>

                <div>
                    <div className='inline-block div'>SM Rarity:</div>
                    <select className='div' name='rarity' onChange={this.handleInput} defaultValue={EPIC}>
                        <option className='common' value={COMMON}>Common</option>
                        <option className='rare' value={RARE}>Rare</option>
                        <option className='epic' value={EPIC}>Epic</option>
                        <option className='legendary' value={LEGENDARY}>Legendary</option>
                    </select>
                </div>

                <div>
                    <div className='inline-block div'>
                        Next Level: <input type="text" name="currentLevel" value={this.state.currentLevel} onChange={this.handleInput} onBlur={this.validateLevelInput}></input>
                    </div>
                    <div className='inline-block div'>
                        Target Level: <input type="text" name="targetLevel" value={this.state.targetLevel} onChange={this.handleInput} onBlur={this.validateLevelInput}></input>
                    </div>
                </div>

                <div>
                    <div className='inline-block div'>Starting gem</div>
                    <div className='inline-block div'>
                        Blue: <input type="text" name='startBlue' className='blue' value={this.state.startBlue} onChange={this.handleInput}></input>
                    </div>
                    <div className='inline-block div'>
                        Red: <input type="text" name='startRed' className="red" value={this.state.startRed} onChange={this.handleInput}></input>
                    </div>
                </div>

                <div>
                    <button className="div btn btn-primary" onClick={this.calculateClicked}>Calculate</button>
                </div>

                {(this.state.rankRequirement > 0) && <div className="alert alert-warning">Reminder: SM of <b>Rank {this.state.rankRequirement}</b> is required to make this promotion</div>}

                <table>
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>Original Cost</th>
                            <th>Blue/Red Crystals used</th>
                            <th>Modified Cost</th>
                            <th># ML reruns needed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.levelArr.map(level =>
                                <tr key={level[0]} style={{ color: 'blue' }}>
                                    <td>{level[0]}</td>
                                    <td>{level[1].toLocaleString("en-US")}</td>
                                    <td>{level[2]}</td>
                                    <td>{this.displayAmount(level[3])}</td>
                                </tr>
                            )
                        }
                        <tr className='bold blue'>
                            <td>Total Blue</td>
                            <td></td>
                            <td>
                                <button className="btn btn-success inline-block" onClick={this.decreaseBlueCrystal} >-</button>
                                <input type="text" name="totalBlue" className="textbox textbox-no-border" value={this.state.totalBlue} onFocus={this.showBorder} onChange={this.calculateBlue} onBlur={this.hideBorder}></input>
                                <button className="btn btn-success inline-block" onClick={this.increaseBlueCrystal} >+</button>
                            </td>
                            <td>{this.displayAmount(this.state.totalModifiedUpgrade)}</td>
                            <td>{Math.ceil((this.state.totalModifiedUpgrade - this.state.startBlue) / this.state.blue)}</td>
                        </tr>
                        {
                            this.state.promotionArr.map(promotion =>
                                <tr key={promotion[0]} style={{ color: 'red' }}>
                                    <td>{promotion[0]}</td>
                                    <td>{promotion[1].toLocaleString("en-US")}</td>
                                    <td>{promotion[2]}</td>
                                    <td>{this.displayAmount(promotion[3])}</td>
                                </tr>
                            )
                        }
                        <tr className='bold red'>
                            <td>Total Red</td>
                            <td></td>
                            <td>
                                <button className="btn btn-success inline-block" onClick={this.decreaseRedCrystal}  >-</button>
                                <input type="text" name="totalRed" className="textbox textbox-no-border" value={this.state.totalRed} onFocus={this.showBorder} onChange={this.calculateRed} onBlur={this.hideBorder}></input>
                                <button className="btn btn-success inline-block" onClick={this.increaseRedCrystal}>+</button>
                            </td>
                            <td>{this.displayAmount(this.state.totalModifiedPromotion)}</td>
                            <td>{Math.ceil((this.state.totalModifiedPromotion - this.state.startRed) / this.state.red)}</td>
                        </tr>
                    </tbody>
                </table >
                <br />
                <div>Credit to <b>jiggle</b> from Discord server for providing all the baseline data used for all calculations in this tool </div>
            </div >
        )
    }
}

export default IMTCrystalPlanner